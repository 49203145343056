@import 'styles/_theme.scss';

.iconButton{
    background-color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 20px;
    padding: $ss-2 $ss-4 $ss-8;
    &:hover{
        background-color: $darkPrimary;
    }
}

.iconTextButton {
    background-color: $lightGrey;
    color: $primary-color;
    fill: $primary-color;
    border: 0;
    border-radius: $ss-4;
    padding: $ss-12 $ss-15;
    display: flex;
    align-items: center;
    gap: $ss-8;
    justify-content: center;
    &:disabled{
        color: $neutralGrey;
        &:hover{
            background-color: $lightGrey;
            color: $neutralGrey;
            fill: $primary-color;
        }
    }
    
}