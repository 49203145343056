@import 'styles/_theme.scss';

.tooltipWrapper {
    display: inline-block;
    position: relative;
}

.tooltipTop {
    border-radius: 4px;
    padding: 5px;
    color: $white;
    background: $neutralGrey;
    font-size: $ts-14;
    width: 200px;
    position: absolute;
    z-index: 1;
    top: -60px;
    right: -29px;
}

.tooltipTop::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -25px;
    border-width: 5px;
    border-style: solid;
    border-color: $neutralGrey transparent transparent transparent;
}

@media only screen and (max-width: 700px) {
    .tooltipTop {
        width: 100%;
        top: -90px;
        right: 0px;
    }
}

