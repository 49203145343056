@import 'styles/_theme.scss';

.list {
  margin: 0;
  border-left: 2px solid $neutralGrey;
  margin-top: $ss-8;
  margin-left: $ss-8;
  padding: $ss-2 0 $ss-4 $ss-8;
  list-style: none;
  max-height: 8 * $ts-14;
  overflow: auto;

  & a {
    text-decoration: none;
    color: $darkestGrey;
    font-size: $ts-14;
    background-color: transparent;
    border: none;
    padding: $ss-2;
    margin: $ss-4 0;
    
    &:hover {
      cursor: pointer;
      color: $primary-color;
    }

    &:focus{
      outline: 1px solid black;
      border-radius: $ss-2;
    }

    &.selectedButton {
      font-weight: 700;
    }
  }
}

.iconButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $ts-16;
  text-align: left;
  background-color: $white !important;
  border: 1px solid $lightGrey;
  border-radius: $ss-8;
  box-shadow: 0px 3px 6px $shadow;
  color: $darkestGrey;
  min-height: $ss-45;
  width: 100%;
  margin: auto 0;
  padding: $ss-2 $ss-15;
  
  &:hover {
    cursor: pointer;
  }
}

.downArrowIcon {
  width: 30px;
  height: 30px;
  position: relative;
  color: $neutralGrey;
}

.invisible {
  display: none;
}

.noData {
  color: $darkestGrey
}