@import 'styles/_theme.scss';

.sideNavContainer {
  padding: $ss-20 $ss-15 0 $ss-15;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hidden {
  overflow: hidden;
  position: absolute;
  border: 0;
  padding: 0;
  margin: -1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px; 
}

.NavTabContainer {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: $ss-8;

  & > br {
    display: none;
  }
}

.navTab {
  display: flex;
  font-size: $ts-16;
  background-color: $white;
  border: 1px solid $lightGrey;
  border-radius: $ss-8;
  box-shadow: 0px 3px 6px $shadow;
  min-height: $ss-45;
  width: 100%;
  margin: auto 0;

  &:global(.react-loading-skeleton) {
    background-color: var(--base-color) !important;
  }

  & a {
    width: 100%;
    text-decoration: none;
    color: $darkestGrey;
    padding: $ss-12 $ss-15;
    margin-top: auto;

    &.disabledLink {
      pointer-events: none;
    }
  }

  &[disabled] {
    opacity: 0.5;
  }
  
  &:not(.selectedReport):not([disabled]):hover {
    background: $lightestGrey;
    border: 1px solid $darkPrimary;
  }
}

.selectedReport {
    font-weight: bold;

    & a {
        pointer-events: none;
    }
}

.printButton {
  font-family: inherit;
  font-size: $ts-16;
  margin: $ss-16 auto $ss-24 auto;
  width: 134px;
  white-space: nowrap;
  
  &:hover{
    background-color: $primary-color;
    fill: $lightGrey;
    color: $lightGrey;
    cursor: pointer;
  }

  & svg {
    min-width: $ss-24;
  }
}

.noData {
  color: $darkestGrey
}