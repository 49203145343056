@import 'styles/_theme.scss';

.reportContainer {
  color: $primary-color;
  height: 100%;
  display: flex;
}

.loadingContainer {
    margin-top: $ss-40;
    height: $ts-32;
    font-size: $ts-20;
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        height: $ts-32;
    }

    & svg {
        height: $ts-32;
        width: $ts-32;
        margin-right: $ss-16;
    }
}

.report {
   width: 100%;
  & > iframe {
    border-width: 0;
    border-style: none;
  }
}