@import 'styles/_theme.scss';

.footer {
    bottom: 0;
    left: 0;
    height: 100%;
    background: $darkestGrey;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    display: flex;
    align-items: center;
}

$ratio: calc($ts-16 / $ts-14);

.copyright {
    padding-top: $ss-14 * $ratio;
    padding-left: $ss-36 * $ratio;
    padding-bottom: $ss-14 * $ratio;
    font-size: $ts-14;
    line-height: $ts-16;
    color: $white;
    font-weight: normal;
    text-decoration: none;
    white-space: pre-wrap;
    display: inline-flex;
    flex-direction: row;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.privacyLink {
    color: $white;
    text-decoration: underline;
    white-space: nowrap;
    display: inline-flex;
    padding: 0 14px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

    &:global(.MuiTypography-root):focus-visible {
        outline: none;
        border: 2px dashed $white;
    }
}

.tooltip {
    padding: -16px -120px;
}

.bullet {
    margin: 0px;
}

@media only screen and (max-width: 700px) {
    .copyright {
        width: 100%;
        margin: $ss-8;
    }
}