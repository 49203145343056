@import 'styles/_theme.scss';

.appContainer {
    display: grid;
    grid-template-rows: 72px 1fr 44px;
    grid-template-columns: 250px auto;
    width: 100%;
    min-height: 100%;
    margin: 0;
}

.reportContainer {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.sideNavContainer {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    background-color: $lightestGrey;
}

.blankReports {
    margin-top: $ss-40;
    height: $ts-32;
    font-size: $ts-20;
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        height: $ts-32;
    }

    & svg {
        height: $ts-32;
        width: $ts-32;
        margin-right: $ss-16;
    }
}