@import 'styles/_theme.scss';

$fontSize: $ts-12;
$ratio: calc($ts-16/$fontSize);

.textLabel {
  font-size: $fontSize;
  color: $neutralGrey;
  margin-top: calc($ratio * $ss-10);
  margin-bottom: calc($ratio * $ss-5);
}