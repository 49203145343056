@import 'styles/_theme.scss';

.circularProgressContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circularProgress {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #003a47;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 